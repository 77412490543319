
























































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import store from '@/store'
import presets from '../presets'

import {
  clone,
  isEqualDeep
} from '../util'

import {
  TranscriptEvent,
  TierFreeText,
  TokenTierType
} from '@/types/transcript'

import contenteditable from './helper/Contenteditable.vue'
import * as copyPaste from '@/service/copy-paste.service'
import { history, mutation } from '@/store/history.store'
import _ from 'lodash'
import * as jsdiff from 'diff'
import bus from '@/service/bus'
import { computeTokenTypesForEvents } from '@/service/token-types.service'
import Transcript from '@/classes/transcript.class'
import settings from '@/store/settings.store'

@Component({
  components: {
    contenteditable
  }
})
export default class SpeakerSegmentTranscript extends Vue {

  @Prop({ required: true }) event!: TranscriptEvent
  @Prop({ required: true }) speaker!: string

  history = history
  transcript = store.transcript!
  tierHeight = 25
  localEvent = clone(this.event)
  localTokens = this.localEvent.speakerEvents[this.speaker]
    ? this.localEvent.speakerEvents[this.speaker].tokens
    : []

  defaultTier = this.transcript.meta.defaultTier
  segmentText = this.localTokens ? this.localTokens.map(t => t.tiers[this.defaultTier].text).join(' ') : ''
  focused = false

  settings = settings

  debouncedUpdateTokenTier = _.debounce(this.updateTokenTier, 300)
  debouncedUpdateEventTier = _.debounce(this.updateEventTier, 300)
  debouncedCommitEvent = _.debounce(this.commitEvent, 300)

  mounted() {
    // tslint:disable-next-line:max-line-length
    bus.$on('updateSpeakerEventText', this.updateTextViaBus)
  }

  destroyed() {
    bus.$off('updateSpeakerEventText', this.updateTextViaBus)
  }

  playEvent(e: TranscriptEvent) {
    if (this.transcript.audio !== null) {
      this.transcript.audio.playEvent(e)
    }
  }

  updateTextViaBus({ eventId, speakerId, text }: { eventId: string, speakerId: string, text: string }) {
    if (Number(eventId) === this.event.eventId && speakerId === this.speaker) {
      this.segmentText = text.replace(/\s/g, ' ')
      this.updateDefaultTier(this.segmentText)
    }
  }

  onBlurEvent() {
    this.focused = false
    if (presets[ this.settings.projectPreset ].autoCorrectDelimiterSpace === true) {
      if (this.event.speakerEvents[ this.speaker ] !== undefined) {
        const text = this.localTokens.map(t => t.tiers[ this.defaultTier ].text).join(' ')
        const replacedText = text.replace(presets[ this.settings.projectPreset ].autoCorrectDelimiterSpaceRegex, ' $1')
        this.updateDefaultTier(replacedText)
      }
    }
  }

  onFocusEvent() {
    this.transcript.selectEvent(this.event)
    if (this.settings.lockScroll === true) {
      this.transcript.scrollToAudioEvent(this.event)
    }
    this.focused = true
  }

  updateTokenTier(text: string|undefined|null, tierType: TokenTierType, index: number) {
    const cleanText = text === undefined || text === null ? '' : text
    this.localTokens[index].tiers[tierType] = { text: cleanText, type: null }
    this.commitEvent()
  }

  updateAllTokenTypes() {
    this.transcript.events = computeTokenTypesForEvents(this.transcript.events, this.defaultTier, [ String(this.speaker) ])
    const thisEvent = this.transcript.getEventById(this.event.eventId)
    if (thisEvent !== undefined) {
      this.updateLocalTokenTypes(thisEvent)
    }
  }

  updateLocalTokenTypes(e: TranscriptEvent) {
    this.localTokens = this.localTokens.map((t, i) => {
      return {
        ...t,
        tiers: {
          ...t.tiers,
          [this.defaultTier]: {
            text: t.tiers[this.defaultTier].text,
            type: e.speakerEvents[this.speaker] ? e.speakerEvents[this.speaker].tokens[i].tiers[this.defaultTier].type : 0
          }
        }
      }
    })
  }

  updateDefaultTier(text: string|undefined|null) {
    const cleanText = text === undefined || text === null ? '' : text
    this.updateLocalTokens(cleanText)
    this.debouncedCommitEvent()
  }

  handleCursor(e: KeyboardEvent, tier: TokenTierType) {
    const s = getSelection()
    const n = s !== null ? s.focusNode : null
    if (e.currentTarget instanceof HTMLElement && s !== null && n !== null) {
      if (e.key === 'ArrowLeft' && s.anchorOffset === 0) {
        this.focusPreviousFrom(e, tier)
      } else if (e.key === 'ArrowRight' && s !== null && s.anchorOffset === n.textContent!.length) {
        this.focusNextFrom(e, tier)
      }
    }
  }

  focusPreviousFrom(e: KeyboardEvent, tier: TokenTierType) {
    e.preventDefault()
    const i = this.transcript.findEventIndexById(this.event.eventId)
    const prevE = this.transcript.events[i > 0 ? i - 1 : 0]
    if (prevE !== undefined) {
      this.transcript.scrollToTranscriptEvent(
        prevE,
        {
          animate: true,
          focusSpeaker: this.speaker,
          focusTier: tier,
          focusRight: true
        }
      )
    }
  }

  focusNextFrom(e: KeyboardEvent, tier: TokenTierType|string) {
    e.preventDefault()
    const i = this.transcript.findEventIndexById(this.event.eventId)
    const nextE = this.transcript.events[i > -1 ? i + 1 : 0]
    if (nextE !== undefined) {
      this.transcript.scrollToTranscriptEvent(
        nextE,
        { animate: true, focusSpeaker: this.speaker, focusTier: tier, focusRight: false }
      )
    }
  }

  @Watch('event', { deep: true })
  onUpdateEvent(newEvent: TranscriptEvent) {
    // update if not focused
    // console.log('watcher', window.getSelection())
    this.localEvent = clone(newEvent)
    this.localTokens = this.localEvent.speakerEvents[this.speaker]
      ? this.localEvent.speakerEvents[this.speaker].tokens
      : []
    this.segmentText = this.localTokens ? this.localTokens.map(t => t.tiers[this.defaultTier].text).join(' ') : ''
    // don’t update if focused
  }

  @Watch('history.undoRedo')
  onUpdateHistory(v: any) {
    if (v) {
      // console.log('history', v, this.history)
      this.history.undoRedo = false
      this.updateAllTokenTypes()
    }
  }

  async cutTokens(e: ClipboardEvent) {
    const s = document.getSelection()
    if (s !== null) {
      const base = (s as any).baseOffset
      const extent = (s as any).extentOffset
      const selectedTokens = Transcript.collectTokensViaOffsets(this.localTokens, this.transcript, base, extent)
      this.localTokens = copyPaste.removeTokensAndTokenParts(this.localTokens, this.transcript, selectedTokens)
      const csv = copyPaste.serializeTokens(selectedTokens)
      if (e.clipboardData !== null) {
        e.clipboardData.setData('text/plain', csv)
      }
      this.segmentText = this.localTokens.map(t => t.tiers[this.defaultTier].text).join(' ')
      await this.$nextTick()
      this.setCursorPosition(e.currentTarget as HTMLElement, Math.min(base, extent))
      this.debouncedCommitEvent()
    } else {
      // nothing is selected, copy nothing.
    }
  }

  copyTokens(e: ClipboardEvent) {
    const s = document.getSelection()
    if (s !== null) {
      const tokens = Transcript.collectTokensViaOffsets(this.localTokens, this.transcript, (s as any).baseOffset, (s as any).extentOffset)
      const csv = copyPaste.serializeTokens(tokens)
      if (e.clipboardData !== null) {
        e.clipboardData.setData('text/plain', csv)
      }
    } else {
      // nothing is selected, copy nothing.
    }
  }

  setCursorPosition(el: HTMLElement, at: number) {
    const pos = Math.min(this.segmentText.length, at)
    const range = document.createRange()
    const sel = window.getSelection()
    range.setStart(el.firstChild || el.parentNode!.firstChild!, pos)
    range.collapse(true)
    if (sel !== null) {
      sel.removeAllRanges()
      sel.addRange(range)
    }
  }

  async pasteTokens(e: ClipboardEvent) {
    // get clipboard data as string
    if (e.clipboardData !== null) {
      const clipboardString = e.clipboardData.getData('text/plain')
      const s = document.getSelection()
      try {
        // TODO: check what is returned here if it’s not a csv
        const tokensTiers = copyPaste.unserializeTokenTiers(clipboardString, this.transcript)
        if (tokensTiers.length > 0 && s !== null) {
          // copy to local variables, because the selection might change.
          const base = (s as any).baseOffset
          const extent = (s as any).extentOffset
          e.preventDefault()
          // update tokens
          this.localTokens = copyPaste.mergePastableTokensAt(
            this.localTokens,
            this.transcript,
            tokensTiers,
            base,
            extent,
            this.transcript.getFirstTokenOrder(this.event, this.speaker)
          )
          // update text presentation
          this.segmentText = this.localTokens.map(t => t.tiers[this.defaultTier].text).join(' ')
          if (e.currentTarget !== null) {
            await this.$nextTick()
            this.setCursorPosition(e.currentTarget as HTMLElement, Math.max(base, extent))
          }
        } else {
          // paste as string.
          document.execCommand('insertHTML', false, clipboardString)
        }
        this.debouncedCommitEvent()
      } catch (e) {
        console.error(e)
        // do nothing (i.e. default OS functionality)
      }
    }
  }

  getTierFreeTextText(tierId: string) {
    return (
      this.localEvent.speakerEvents[this.speaker] !== undefined &&
      this.localEvent.speakerEvents[this.speaker].speakerEventTiers !== undefined &&
      this.localEvent.speakerEvents[this.speaker].speakerEventTiers[tierId] !== undefined
        ? (this.localEvent.speakerEvents[this.speaker].speakerEventTiers[tierId] as TierFreeText).text
        : ''
    )
  }

  get isMarkedWithFragment(): boolean {
    const last = _(this.localTokens).last()
    return last !== undefined && last.tiers[this.defaultTier].text.endsWith('=')
  }

  get firstTokenFragmentOf(): number|null {
    const speakerEvent = this.event.speakerEvents[this.speaker]
    if (speakerEvent !== undefined) {
      const firstToken = _(speakerEvent.tokens).first()
      if (firstToken !== undefined && firstToken.fragmentOf) {
        return firstToken.fragmentOf
      } else {
        return null
      }
    } else {
      return null
    }
  }

  get secondaryTokenTiers() {
    return this.secondaryTiers.filter(t => t.type === 'token')
  }

  get secondaryFreeTextTiers() {
    return this.secondaryTiers.filter(t => t.type === 'freeText')
  }

  get secondaryTiers() {
    return this.transcript.meta.tiers.filter(t => t.id !== this.defaultTier && t.show[this.speaker] === true)
  }

  get tokens() {
    return this.event.speakerEvents[this.speaker].tokens
  }

  tokenizeText(text: string) {
    return text.split(' ').filter((t) => t !== '')
  }

  viewAndSelectAudioEvent(e: TranscriptEvent) {
    this.transcript.selectEvent(e)
    this.transcript.scrollToAudioEvent(e)
  }

  colorFromTokenType(id: number|null): string {
    const c = presets[ this.settings.projectPreset ].tokenTypes.concat({
      name: 'placeholder',
      type: 'single',
      regex: /⦿/,
      color: 'grey',
      id: -2
    }).find(tt => tt.id === id)
    if (c) {
      return c.color
    } else {
      return 'red'
    }
  }

  commitEvent() {
    const oldIndex = this.transcript.findEventIndexById(this.event.eventId)
    const oldEvent = this.transcript.events[oldIndex]
    const newEvent: TranscriptEvent = {
      ...this.localEvent,
      speakerEvents: {
        [ this.speaker ]: {
          ...this.localEvent.speakerEvents[this.speaker],
          tokens: this.localTokens
        }
      }
    }
    if (!isEqualDeep(newEvent, oldEvent)) {
      mutation(this.transcript.updateSpeakerEvent(newEvent, Number(this.speaker)))
      this.updateAllTokenTypes()
    } else {
      // nothing to update
    }
  }

  isValidTierEventText(text: string): boolean {
    return text.trim() !== ''
  }

  hasEventTierChanged(text: string, tierId: string): boolean {
    return this.localEvent.speakerEvents[this.speaker].speakerEventTiers[tierId].text !== text
  }

  eventTierExists(tierId: string): boolean {
    return this.localEvent.speakerEvents[this.speaker] !== undefined &&
      this.localEvent.speakerEvents[this.speaker].speakerEventTiers !== undefined &&
      this.localEvent.speakerEvents[this.speaker].speakerEventTiers[tierId] !== undefined
  }

  createEventTier(text: string, tierId: string) {
    this.localEvent = {
      ...this.localEvent,
      speakerEvents: {
        ...this.localEvent.speakerEvents,
        [ this.speaker ]: {
          ...this.localEvent.speakerEvents[this.speaker],
          speakerEventTiers: {
            ...(this.localEvent.speakerEvents[this.speaker] || {}).speakerEventTiers,
            [ tierId ]: {
              id: String(Transcript.makeEventTierId()),
              type: 'freeText',
              text
            }
          }
        }
      }
    }
  }

  deleteEventTier(tierId: string) {
    const e = this.localEvent
    delete e.speakerEvents[this.speaker].speakerEventTiers[tierId]
    this.localEvent = e
  }

  updateEventTierText(text: string, tierId: string) {
    this.localEvent.speakerEvents[this.speaker].speakerEventTiers[tierId].text = text
  }

  updateEventTier(text: string|null|undefined, tierId: string) {
    const cleanText = text === null || text === undefined ? '' : text
    if (this.eventTierExists(tierId) && this.hasEventTierChanged(cleanText, tierId)) {
      if (this.isValidTierEventText(cleanText)) {
        // update
        this.updateEventTierText(cleanText, tierId)
        this.commitEvent()
      } else {
        // delete
        this.deleteEventTier(tierId)
        this.commitEvent()
      }
    } else if (this.isValidTierEventText(cleanText)) {
      // create
      if (this.localTokens.length === 0) {
        // if there are no tokens, put the placeholder.
        this.updateLocalTokens(this.settings.placeholderToken)
      }
      this.createEventTier(cleanText, tierId)
      this.commitEvent()
    }
  }

  updateLocalTokens(text: string) {
    // await requestFrameAsync()
    const newTokens = this.tokenizeText(text).map((t, i) => {
      return { text: t, index: i, id: -1 }
    })
    const oldTokens = this.localTokens.map((t, i) => ({
      text: t.tiers[this.defaultTier].text,
      id: t.id,
      index: i
    }))
    const hunks = jsdiff.diffArrays(oldTokens, newTokens, { comparator: (l, r) => l.text === r.text })
    // console.log({ hunks })
    const changes = _(hunks)
      .filter((h) => h.added === true || h.removed === true)
      .map((h) => h.value.map(v => ({
        ...v,
        type: (() => {
          if (h.added === true) {
            return 'add'
          } else if (h.removed) {
            return 'remove'
          }
        })()
      })))
      .flatten()
      .groupBy('index')
      .map((g) => {
        if (g.length > 1) {
          return [{
            ...g[1],
            id: g[0].id,
            type: 'update'
          }]
        } else {
          return g
        }
      })
      .flatten()
      .value()

    let addedCounter = 0
    _.each(changes, (change, i) => {
      if (change.type === 'update') {
        this.localTokens[change.index + addedCounter] = {
          ...this.localTokens[change.index + addedCounter],
          tiers: {
            ...this.localTokens[change.index + addedCounter].tiers,
            [ this.defaultTier ]: {
              text: change.text,
              type: Transcript.getTokenTypeFromToken(change.text, presets[settings.projectPreset]).id
            }
          }
        }
      } else if (change.type === 'add') {
        this.localTokens.splice(change.index + addedCounter, 0, {
          id: Transcript.makeTokenId(),
          fragmentOf: Number(i) === 0 ? this.firstTokenFragmentOf : null,
          order: -1,
          sentenceId: -1, // how?
          tiers: {
            text: {
              text: '',
              type: null
            },
            ortho: {
              text: '',
              type: null
            },
            phon: {
              text: '',
              type: null
            },
            [ this.defaultTier ]: {
              text: change.text,
              type: Transcript.getTokenTypeFromToken(change.text, presets[settings.projectPreset]).id
            }
          }
        })
        addedCounter = addedCounter + 1
      } else if (change.type === 'remove') {
        if (change.id !== -1 && this.transcript.meta.lockedTokens.indexOf(change.id) > -1) {
          // can’t delete because it’s locked.
          alert('This Token has meta data attached to it. It must be removed, before it can be fully deleted.')
          // update display text
          setTimeout(() => {
            // tslint:disable-next-line:max-line-length
            this.segmentText = this.localTokens ? this.localTokens.map(t => t.tiers[this.defaultTier].text).join(' ') : ''
          }, 16)
        } else {
          this.localTokens.splice(change.index + addedCounter, 1)
          addedCounter = addedCounter - 1
        }
      }
    })
    this.localTokens = this.localTokens.map((t, i) => {
      return { ...t, order: (this.transcript.getFirstTokenOrder(this.event, this.speaker)) + i }
    })
    return this.localTokens
  }

  get textStyle() {
    if (this.settings.darkMode === true) {
      return {
        color: 'white'
      }
    } else {
      return {
        color: 'white',
        caretColor: 'black'
      }
    }
  }

}
